import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Location} from '@angular/common';
import {filter} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class NavigationService {
  private history: string[] = [];

  constructor(private router: Router, private location: Location) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => this.history.push(event.urlAfterRedirects));
  }

  async back(fallback: string = '/') {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      await this.router.navigateByUrl(fallback);
    }
  }
}
